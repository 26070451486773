@use "mixins" as *
.blockContent
    background: var(--account_detailsCompactInfoFormContentBg)
    border-top: 2px solid var(--body_bg)
    border-bottom: 2px solid var(--body_bg)
    padding: 10px 10px 0

    > div
        margin-bottom: 10px
        label
            text-transform: capitalize
            font-size: 14px
            line-height: 16px
            margin-bottom: 6px
            display: block
            padding: 0 0 0 8px!important

        &:has(:global(.viewPass)) + p
            background: var(--account_detailsCompactInfoFormBg)
            font-size: 14px
            line-height: 18px
            color: rgb(var(--primaryColor))
            padding: 6px 8px
            border-radius: 4px
            margin: 10px 0

        input, div > select, :global(.intl-tel-input .phone-widget),
        :global(.intl-tel-input.separate-dial-code .flag-container)
            height: 46px

.field
    margin-bottom: 10px

.iconVerified
    margin-left: 12px
    svg
        width: 18px
        height: 16px

.verifyBtn
    margin-left: auto
    background: var(--acount_verifyBtnBg)
    border-radius: var(--base_borderRadius)
    font-size: 14px
    padding: 0 6px
    min-width: 64px
    @include bold
    justify-content: center
    height: 34px
    line-height: 34px
    text-transform: uppercase
    color: #fff
    max-width: 100px
    @include ellipsis
    &:active
        background: var(--acount_verifyBtnActive)

.phone, .email
    display: flex

    &:has(.verification)
        display: block
    > div:not(.verification)
        flex: 1
    .verifyBtn
        margin-left: 10px

    &:has(.verification > div > section)
        position: relative
        padding-right: 28px

.warningIcon
    margin-left: auto
    svg
        width: 22px
        height: 22px

.verification > div
    margin-top: 14px
    font-size: 16px
    > section
        margin: 0
        font-size: 14px
        color: rgb(var(--primaryColor))
        :global(.svg-icon)
            position: absolute
            right: 0
            top: 10px
            margin: 0
            svg
                width: 18px
                height: 16px

    > div:has(strong)
        padding-bottom: 10px
        line-height: 18px
        border-bottom: 1px solid #2B2C31
        border-bottom: 1px solid var(--account_detailsCompactInfoFormBg)
    form
        > div
            display: flex
            flex-wrap: wrap
            padding-top: 30px

            div
                flex: 1
                label
                    text-transform: capitalize
                    font-size: 14px
                    line-height: 16px
                    display: block
                    position: absolute
                    margin-top: -22px
            > p
                width: 100%
                margin: 10px 0 0

        button
            margin: 0 0 0 10px
            width: auto
            min-width: 114px
            font-size: 14px

        > p
            margin: 10px 0 0

.message
    font-size: 14px
    line-height: 16px
    margin: 8px 0

.blockFooter
    @include flex
    height: 38px

    > button
        flex: 1
        background: transparent!important
        font-size: 14px
        border-radius: 0
        position: relative
        height: 100%
        &:disabled
            opacity: .3
        > span
            position: relative
            z-index: 1
            @include flex
        :global(.svg-icon)
            margin-right: 10px
            svg
                height: 12px
                width: auto
        &:nth-child(1)
            color: #fff
        &:nth-child(2)
            color: rgb(var(--thirdaryColor))
            svg
                path
                    fill: rgb(var(--thirdaryColor))

        &:active:before, &:hover:before
            content: ""
            height: 30px
            background: var(--account_detailsCompactInfoFormButtonActiveBg)
            border-radius: 30px
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
            width: 0
            animation: buttonHoverEffect .3s
            @keyframes buttonHoverEffect
                0%
                    width: 0px
                80%
                    opacity: 1
                    width: 80%
                100%
                    width: 80%
                    opacity: 0
