@use "mixins" as *
.btnContainer
    @include flex

    button
        width: 40px
        min-width: 40px
        height: 32px
        flex: 1

        &:first-of-type
            position: relative

        :global(.loader) span
            font-size: 12px

        &:nth-child(2)
            margin-left: 14px

.saveIcon
    span
        @include flex
        svg
            width: 14px
            height: 10px
            path
                fill: var(--button_color)
.cancelIcon
    span
        @include flex
        svg
            path
                fill: var(--txt_color)

.contentDefault
    padding: 16px 12px 0
    position: relative

    div:has(:global(.field-error))
        input
            box-shadow: inset 0 0 0 1px rgb(var(--particularColor2))

    > div:not(.btnContainer):not(.userInfoNotification)
        padding-bottom: 10px
        label,
        :global(.intl-tel-input-label)
            text-transform: capitalize
            margin-bottom: 6px
            font-size: 12px
            display: block

    &:after
        top: 36px

@media screen and (max-width: 320px) and (orientation: portrait)
    .btnContainer
        button
            width: 34px
            min-width: 34px
            height: 30px
            flex: 1

            &:nth-child(2)
                margin-left: 6px
