@use "mixins" as *
.infoForm
    margin-bottom: 6px
    background: var(--acount_detailsSectionBg)
    border-radius: 3px
    overflow: hidden
    position: relative

.header
    @include flex
    justify-content: space-between
    height: 44px
    border-radius: 3px 3px 0 3px
    padding: 0 12px
    text-transform: uppercase
    background: var(--acount_detailsSectionHeaderBg)
    box-shadow: inset 1px 0 0 rgb(var(--particularColor))
    color: #fff
    position: relative
    z-index: 1

.userInfoNotification
    margin-bottom: 8px
    padding-bottom: 0
