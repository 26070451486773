@use "mixins" as *
.changePassword
  padding: 0 0 12px

  div:has(:global(.field-error))
    input
      box-shadow: inset 0 0 0 1px rgb(var(--particularColor2))

  form
    input
      padding-right: 36px

    label
      padding-top: 12px
      + div
        position: relative

  p
    color: var(--account_detailsTxtColor)
    padding: 12px 0 0
    line-height: 16px
    @include flex
    :global(.svg-icon)
      margin-right: 10px

.passwordChanged
  margin-top: 12px
  display: flex
  color: #42b147
  @include bold
  background: var(--body_bg)
  border-radius: 4px
  min-height: 80px
  flex-direction: column
  justify-content: center
  padding: 0 10px
  span
    @include flex
    :global(.imageNext)
      margin-right: 10px
  button
    height: 34px
    margin-top: 10px
    width: 100%

.contentDefault
  composes: contentDefault from "../../info/form/layouts/default/default.module.sass"

  form
    :global(.viewPass)
      max-height: 36px

.btnContainer
  composes: btnContainer from "../../info/form/layouts/default/default.module.sass"

.saveIcon
  composes: saveIcon from "../../info/form/layouts/default/default.module.sass"

.cancelIcon
  composes: cancelIcon from "../../info/form/layouts/default/default.module.sass"

// compact
.blockContent
  composes: blockContent from "../../info/form/layouts/compact/compact.module.sass"

  form
    :global(.viewPass)
      max-height: 46px

.blockFooter
  composes: blockFooter from "../../info/form/layouts/compact/compact.module.sass"
