@use "mixins" as *
.details
    padding: 0 8px calc(var(--menuHeight) + 20px)
    position: relative

.header
    @include flex
    flex-direction: column
    padding: 24px 0 10px
    color: #fff
    h2
        font-size: 20px
        line-height: 22px
        text-transform: capitalize
        margin-bottom: 20px

.userIcon
    @include flex
    position: relative

.verificationReminder
    position: absolute
    background: rgb(var(--particularColor2))
    width: 16px
    height: 16px
    @include flex
    justify-content: center
    border-radius: 100%
    right: -6px
    top: -6px

    > span
        @include flex
        justify-content: center
        path
            fill: #fff

.userInfo
    @include flex
    font-size: 16px
    line-height: 18px
    margin-top: 14px
    :global(.svg-icon)
        margin-left: 8px
        svg
            width: 14px
            height: 16px

.verifyMessage
    @include flex
    margin-top: 10px
    border-radius: 6px
    padding: 6px 10px 4px
    background: rgba(var(--particularColor2), .3)
    border: 1px solid rgba(var(--particularColor2), .25)
    font-size: 16px
    line-height: 18px
    :global(.svg-icon)
        margin-right: 10px
        svg
            width: 22px
            height: 22px

.personalDetailsTxt
    line-height: 18px
    margin-top: 20px
    font-size: 14px

.verificationBtn
    width: 100%
    padding: 6px 10px
    @include flex
    min-height: 52px
    font-size: 16px
    color: #fff
    background: var(--account_detailsCompactInfoFormBg)
    margin-bottom: 6px
    text-transform: capitalize
    justify-content: flex-start
    font-weight: normal
    &:active
        background: var(--account_detailsCompactInfoFormContentBg)

    :global(.svg-icon)
        margin-right: 10px
        svg
            width: 32px
            height: 32px
