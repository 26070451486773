@use "mixins" as *
.header
  @include accountStickyHeader
  margin-bottom: 0
  > div:first-of-type
    max-width: calc(100% - 128px)
    overflow: hidden

    > span
      max-width: 100%
      strong
        text-transform: initial
        margin-left: 4px

        @include ellipsis

.back
  @include backArrowBtn
  min-width: 14px

.referralTabs
  composes: myBetsTabs from "components/layout/bets/account-bets-layout.module.sass"
  margin-left: auto

  .casinoSlot
    margin-right: 1px

  .referralTabActive
    background: var(--account_tabActiveBg)
    pointer-events: none
    svg
      path
        fill: var(--button_color) !important

.referralList
  flex: 1
  display: flex
  flex-direction: column

  > div:first-child
    @include flex
    justify-content: space-between
    margin-bottom: 10px
    height: 54px
    min-height: 54px
    border-bottom: 1px dotted var(--acount_headerBorder)

    .dropdown
      flex: 1

      select
        background: var(--ddl_buttonBg)
        box-shadow: inset 0 1px var(--ddl_buttonShadow)

      &:first-of-type
        margin-right: 10px
        max-width: 40%
  .tableContainer
    flex: 1
    position: relative
    padding-bottom: var(--menuHeight)
    min-height: 200px

.table
  width: 100%
  background: var(--account_referralReportTableBg)
  table-layout: fixed

  :global(.loader)
    position: absolute!important

  th,
  td:not(.noDataTd)
    height: 38px
    vertical-align: middle
    padding: 0 8px
    text-align: left
    word-wrap: break-word

    &:first-of-type
      width: 40%

    &:not(:first-of-type)
      border-left: 1px solid var(--body_bg)

  .turnover span:nth-child(2)
    display: block
    margin: 2px 0 -2px

  th
    color: #fff
    @include bold
    border-top: 0 !important

  td
    line-height: 14px

  tr:nth-child(2n+1) td
    background: var(--account_betsItemBg)

  .noDataTd
    background: var(--body_bg) !important

  .noDataTd :global
    .no-data
      position: absolute
      left: 50%
      transform: translateX(-50%)
      height: calc(100% - 38px)

      :global(.imageNext)
        margin: 0 0 30px !important

.referralReportFooter
  position: sticky
  bottom: var(--menuHeight)
  background: var(--body_bg)
  padding-bottom: 8px

.footerDiv
  @include flex
  margin-top: 2px
  @include bold
  background: var(--account_betsItemBg)

  > div
    padding: 0 10px
    height: 40px
    @include flex

    &:first-child
      min-width: 140px
      max-width: 140px
      justify-content: flex-end
      color: #a9b5c9
      border-right: 1px solid var(--body_bg)
      padding-left: 4px

    &:last-child
      color: #fff
      padding-right: 4px

      > span:not(:first-of-type)
        margin-left: 4px

.getBonus
  background: #27ae60
  color: #fff
  text-transform: uppercase
  margin-top: 4px
  width: 100%
  margin-bottom: 12px
  position: relative

  &:active
    background: #1d9550

  :global(.svg-icon)
    position: absolute
    right: 8px
    top: 50%
    transform: translateY(-50%)

    path
      fill: #fff

  &.paid
    background: rgba(#27ae60, 0.4)
    pointer-events: none
    padding: 0 22px

  &.getBonusDisabled
    background: rgba(#dfe6f3, 0.3)
    color: rgba(#21262c, 0.7)
    pointer-events: none
