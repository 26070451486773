@use "mixins" as *
.infoForm
    background: var(--account_detailsCompactInfoFormBg)
    border-radius: 8px
    margin-bottom: 6px
    overflow: hidden
    position: relative
    padding-bottom: 0!important

    &.opened
        .header:after
            transform: rotate(0)

.header
    padding: 6px 10px
    @include flex
    min-height: 52px
    font-size: 16px
    color: #fff
    background: var(--account_detailsCompactInfoFormBg)
    position: relative
    z-index: 3

    &::after
        content: ""
        @include backArrowBtn
        transform: rotate(-90deg)
        transition: all .3s
        margin-left: 12px

.blockName
    margin-right: auto
    flex: 1
    @include flex
    overflow: hidden

    > :global(.svg-icon):first-of-type
        margin-right: 10px
        svg
            width: 32px
            height: 32px

        + span
            @include ellipsis

.userInfoNotification
    margin-bottom: 8px
    padding-bottom: 0
